<script>
import { ref, watch } from "vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import formAnagraficaCliente from "@/components/widgets/formAnagraficaCliente";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DropZone from "@/components/widgets/dropZone";
import useVuelidate from "@vuelidate/core";

import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";

export default {
  page: {
    title: "Create Project",
    meta: [{ name: "description", content: appConfig.description }],
  },
  setup() {
    let files = ref([]);
    let dropzoneFile = ref("");
    const drop = (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
      files.value.push(dropzoneFile.value);
    };
    const selectedFile = () => {
      dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
      files.value.push(dropzoneFile.value);
    };
     watch(
      () => [...files.value],
      (currentValue) => {
        return currentValue;
      }
    );
    return { dropzoneFile, files, drop, selectedFile, v$: useVuelidate() };
  },
  data() {
    return {
      showMainCliente:false,
      showMainProgect:true,
      showDetailDescProgect:false,
      showAmmProgect:true,
      showTechProgect:true,
      showAdvancedProgect:false,
      title: "Crea Pratica/Progetto",
      project:{
        tipo:'commessa', // Tipologia di lavoro => 'Commessa', prevalentement
        code:'code',
        fonteAcquisizione:'',
        tipologiaCommessa:'', // privato/azienda
        beneficioFiscale:'',
        sopralluogo:'',
        dettagliSopralluogo:{},
        registrazioneAmministrativa:'no',
        dettagliRegistrazioneAmministrativa:{},
        modPagamento:'',
        subappalto:'no',
        dettagliSubappalto: 'no',
        ftv:'no',
        dettagliFtv:{},
        suap:'no',
        autorizzazioneSuap:'no',
        praticaEnel:'no',
        dettagliPraticaEnel:{},
        cantiere:'no',
        cantiereID: '',   
        dettagliCantiere:{},
        progettazione: 'no',
        dettagliProgettazione:{},
        parent: '',
        name: '',
        descriptions: '',
        status:'init',
        start:0,
        end:0,
        start_tmp:0,
        end_tmp:0,
        year:0,
        month:0,
        day:0,
        user:{},
      },
      opzioneBeneficio:[],
      items: [
        {
          text: "Pratiche/progetti",
          href: "/",
        },
        {
          text: "Crea Pratica/Progetto",
          active: true,
        },
      ],
      value: ["C#", "HTML", "CSS"],
      value3: ["Private"],
      value4: ["Designing"],
      value5: ["Ellen Smith"],
      value1: ["Inprogress"],
      value2: ["High"],
      editor: ClassicEditor,
      editorData:
        "",
      content: "",


    };
  },
  methods: {
    deleteRecord(ele) {
      ele.target.parentElement.parentElement.remove();
    },
    cambiaOpzioneBeneficio(){
        let privato = [
          {"value":"sconto-fattura","text":"Sconto in fattura"},
          {"value":"conto-termico","text":"Conto Termico"},
          {"value":"nessuno","text":"Nessuno"},
        ]
        let azienda = [
          {"value":"credito-imposta","text":"Credito d'imposta"},
          {"value":"sconto-fattura","text":"Sconto in fattura"},
          {"value":"nessuno","text":"Nessuno"},
        ]    
       
      if (this.project.tipologiaCommessa =='privato'){
        this.opzioneBeneficio = privato
      } else {
        this.opzioneBeneficio = azienda
      }   
    },
  },
  components: {
    formAnagraficaCliente,
    DropZone,
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
       <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Anagrafica cliente</h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                <label for="FormSelectDefault" class="form-label text-muted"
                  >Mostra</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="FormSelectDefault"
                                        v-model="showMainCliente"

                />
              </div>
            </div>
          </div>
          <!-- end card header -->
          <div class="card-body" v-if="showMainCliente">
            <!-- Form anagrafica -->
             <formAnagraficaCliente />
            <!-- / Form anagrafica -->
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Informazioni generali</h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                <label for="FormSelectDefault" class="form-label text-muted"
                  >Mostra</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="FormSelectDefault"
                  v-model="showMainProgect"

                />
              </div>
            </div>
          </div>
          <!-- end card header -->
          <div class="card-body" v-if="showMainProgect">
            <p class="text-muted">
              Use <code>&lt;select&gt;</code> attribute with numerous options to
              show value with choice's option.
            </p>
  
            <div class="live-preview">
              <div class="row pb-2">
                <div class="col-lg-2 col-md-12">
                    <label for="projectCode" class="form-label">Codice pratica</label>
                    <input type="text" class="form-control" id="projectCode" placeholder="Codice pratica"  v-model="project.code">
                </div>
              </div>
              <div class="row  pb-2">
                <div class="col-lg-3 col-md-12">
                   <label for="projectFonteAcquisizione" class="form-label">Commessa acquisita da</label>

                  <select
                    class="form-select mb-3"
                    aria-label="Fonte commessa"
                    v-model="project.fonteAcquisizione"
                  >
                    <option value="commerciale">Rete commerciale</option>
                    <option value="diretto">Diretto</option>
                    <option value="bando">Bando pubblico</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="live-preview">
     
              <div class="row  pb-2">
                <div class="col-lg-3 col-md-12">
                  <label for="projectFonteAcquisizione" class="form-label">Tipologia cliente</label>
                  <select
                    class="form-select mb-3"
                    aria-label="Fonte commessa"
                    v-model="project.tipologiaCommessa"
                    @change="cambiaOpzioneBeneficio()"
                  >

                    <option value="privato">Privato</option>
                    <option value="azienda">Azienda</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="live-preview">
     
              <div class="row  pb-2">
                <div class="col-lg-3 col-md-12">
                  <label for="projectFonteAcquisizione" class="form-label">Beneficio fiscale</label>
                  <select
                    class="form-select mb-3"
                    aria-label="Fonte commessa"
                    v-model="project.beneficioFiscale"
                  >
                    <option v-for="item in opzioneBeneficio" :value="item.value" :key="item">{{item.text}}</option>
                   
                  </select>
                </div>
              </div>
              <div class="row  pb-2">
                <div class="col-lg-3 col-md-12">
                  <label for="projectFonteAcquisizione" class="form-label">Esito sopralluogo</label>
                  <select
                    class="form-select mb-3"
                    aria-label="Fonte commessa"
                    v-model="project.sopralluogo"
                  >
                    <option value="nessuno">Nessuno</option>
                    <option value="positivo">Positivo</option>
                    <option value="negativo">Negativo</option>
                   
                  </select>
                </div>
              </div>
                <!-- Registrazione amministrativa -->
            <!-- Form anagrafica -->
           
          <div class="row  pb-2">

              </div>
            </div>  
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!--Registrazione amministrativa -->
      <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Registrazione amministrativa</h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                <label for="FormSelectDefault" class="form-label text-muted"
                  >Mostra</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="FormSelectDefault"
                  v-model="showAmmProgect"

                />
              </div>
            </div>
          </div>
          <!-- end card header -->
          <div class="card-body" v-if="showAmmProgect">
          
  
            <div class="live-preview">
              <div class="row pb-2">
                <div class="col-lg-2 col-md-12">
                    <label for="projectCode" class="form-label">Codice pratica</label>
                    <input type="text" class="form-control" id="projectCode" placeholder="Codice pratica"  v-model="project.code">
                </div>
              </div>
              <div class="row  pb-2">
                <div class="col-lg-3 col-md-12">
                   <label for="projectFonteAcquisizione" class="form-label">Commessa acquisita da</label>

                  <select
                    class="form-select mb-3"
                    aria-label="Fonte commessa"
                    v-model="project.fonteAcquisizione"
                  >
                    <option value="commerciale">Rete commerciale</option>
                    <option value="diretto">Diretto</option>
                    <option value="bando">Bando pubblico</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="live-preview">
     
              <div class="row  pb-2">
                <div class="col-lg-3 col-md-12">
                  <label for="projectFonteAcquisizione" class="form-label">Tipologia cliente</label>
                  <select
                    class="form-select mb-3"
                    aria-label="Fonte commessa"
                    v-model="project.tipologiaCommessa"
                    @change="cambiaOpzioneBeneficio()"
                  >

                    <option value="privato">Privato</option>
                    <option value="azienda">Azienda</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="live-preview">
     
              <div class="row  pb-2">
                <div class="col-lg-3 col-md-12">
                  <label for="projectFonteAcquisizione" class="form-label">Beneficio fiscale</label>
                  <select
                    class="form-select mb-3"
                    aria-label="Fonte commessa"
                    v-model="project.beneficioFiscale"
                  >
                    <option v-for="item in opzioneBeneficio" :value="item.value" :key="item">{{item.text}}</option>
                   
                  </select>
                </div>
              </div>
              <div class="row  pb-2">
                <div class="col-lg-3 col-md-12">
                  <label for="projectFonteAcquisizione" class="form-label">Esito sopralluogo</label>
                  <select
                    class="form-select mb-3"
                    aria-label="Fonte commessa"
                    v-model="project.sopralluogo"
                  >
                    <option value="nessuno">Nessuno</option>
                    <option value="positivo">Positivo</option>
                    <option value="negativo">Negativo</option>
                   
                  </select>
                </div>
              </div>
                <!-- Registrazione amministrativa -->
            <!-- Form anagrafica -->
           
          <div class="row  pb-2">

              </div>
            </div>  
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!--/Registrazione amministrativa -->
<!--Registrazione tecnica -->
  
      <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Registrazione tecnica</h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                <label for="FormSelectDefault" class="form-label text-muted"
                  >Mostra</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                 
                  v-model="showTechProgect"

                />
              </div>
            </div>
          </div>
          <!-- end card header -->
          <div class="card-body" v-if="showTechProgect">
          
  
            <div class="live-preview">
          
              <div class="row  pb-2">
                <div class="col-lg-3 col-md-12">
                   <label for="projectFonteAcquisizione" class="form-label">Impianto fotovoltaico?</label>

                  <select
                    class="form-select mb-3"
                    aria-label="Fonte commessa"
                    v-model="project.fotovoltaico"
                  >
                    <option value="no">No</option>
                    <option value="si">Sì</option>
                    
                  </select>
                </div>
                  <div class="col-lg-3 col-md-12">
                  <div v-if="project.fotovoltaico=='si'">
                    <label for="projectFonteAcquisizione" class="form-label">Pratica ENEL</label>
                    <select
                      class="form-select mb-3"
                      aria-label="Fonte commessa"
                      v-model="project.praticaEnel"
                    >
                      <option value="no">No</option>
                      <option value="si">Sì</option>                   
                    </select>
                  </div>
                </div>
                <div class="col-lg-3 col-md-12">
                  <div v-if="project.fotovoltaico=='si' && project.praticaEnel=='si'">
                    <label for="projectFonteAcquisizione" class="form-label">Dettagli pratica ENEL</label>
                    <ckeditor v-model="project.dettagliPraticaEnel.descrizione" :editor="editor"></ckeditor>
                  </div>
                </div>
              </div>
            </div>
            <div class="live-preview" v-if="project.fotovoltaico=='si'">
     
              <div class="row  pb-2">
                <div class="col-lg-3 col-md-12">
                  <label for="projectFonteAcquisizione" class="form-label">SUAP?</label>
                  <select
                    class="form-select mb-3"
                    aria-label="Fonte commessa"
                    v-model="project.suap"
                    @change="cambiaOpzioneBeneficio()"
                  >
                    <option value="no">No</option>
                    <option value="si">Sì</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="live-preview">
              <div class="row  pb-2">
                <div class="col-lg-3 col-md-12">
                  <label for="projectFonteAcquisizione" class="form-label">Esito sopralluogo</label>
                  <select
                    class="form-select mb-3"
                    aria-label="Fonte commessa"
                    v-model="project.sopralluogo"
                  >
                    <option value="nessuno">Nessuno</option>
                    <option value="positivo">Positivo</option>
                    <option value="negativo">Negativo</option>
                   
                  </select>
                </div>
              </div>
                <!-- Registrazione amministrativa -->
            <!-- Form anagrafica -->
           
          <div class="row  pb-2">

              </div>
            </div>  
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
<!--/Registrazione tecnica -->

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Operazioni avanzate</h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                <label for="FormSelectDefault" class="form-label text-muted"
                  >Mostra</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  v-model="showAdvancedProgect"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="showAdvancedProgect">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-header align-items-center d-flex">
   
          </div>
          <div class="card-body" v-if="showMainProgect">
            
            <div class="mb-3">
              <label class="form-label" for="project-title-input"
                >Denominazione pratica</label
              >
              <input
                type="text"
                class="form-control"
                id="project-title-input"
                placeholder="Enter project title"
              />
            </div>

            <div class="mb-3">
              <label class="form-label" for="project-thumbnail-img"
                >Thumbnail Image</label
              >
              <input
                class="form-control"
                id="project-thumbnail-img"
                type="file"
                accept="image/png, image/gif, image/jpeg"
              />
            </div>

            <div class="mb-3">
              <label class="form-label">Descrizione</label>
              <ckeditor v-model="editorData" :editor="editor"></ckeditor>
            </div>

            <div class="row">
              <div class="col-lg-4">
                <div class="mb-3 mb-lg-0">
                  <label for="choices-priority-input" class="form-label"
                    >Priorità</label
                  >

                  <Multiselect
                    v-model="value2"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="true"
                    :options="[
                      { value: 'Alta', label: 'High' },
                      { value: 'Media', label: 'Medium' },
                      { value: 'Bassa', label: 'Low' },
                    ]"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="mb-3 mb-lg-0">
                  <label for="choices-status-input" class="form-label"
                    >Status</label
                  >

                  <Multiselect
                    v-model="value1"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="true"
                    :options="[
                      { value: 'Inprogress', label: 'Inprogress' },
                      { value: 'Completed', label: 'Completed' },
                    ]"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div>
                  <label for="datepicker-deadline-input" class="form-label"
                    >Deadline</label
                  >

                  <flat-pickr
                    v-model="date"
                    :config="config"
                    class="form-control"
                  ></flat-pickr>
                </div>
              </div>
            </div>

          </div>
          <!-- end card body -->
        </div>

        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Allegati</h5>
          </div>
          <div class="card-body">
            <div>
              <p class="text-muted">Aggiungi allegati</p>

              <DropZone @drop.prevent="drop" @change="selectedFile" class="mb-2" />
              <div class="vstack gap-2">
              <div
                class="border rounded"
                v-for="(file, index) of files"
                :key="index"
              >
               <div class="d-flex align-items-center p-2">
                  <div class="flex-grow-1">
                    <div class="pt-1">
                      <h5 class="fs-14 mb-1" data-dz-name="">
                        {{ file.name }}
                      </h5>
                      <p class="fs-13 text-muted mb-0" data-dz-size="">
                        <strong>{{ file.size / 1024 }}</strong> KB
                      </p>
                      <strong
                        class="error text-danger"
                        data-dz-errormessage=""
                      ></strong>
                    </div>
                  </div>
                  <div class="flex-shrink-0 ms-3">
                    <button
                      data-dz-remove=""
                      class="btn btn-sm btn-danger"
                      @click="deleteRecord"
                    >
                      Rimuovi
                    </button>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
        <!-- end card -->
        <div class="text-end mb-4">
          <button type="submit" class="btn btn-danger w-sm me-1">Rimuovi</button>
          <button type="submit" class="btn btn-secondary w-sm me-1">
            Draft
          </button>
          <button type="submit" class="btn btn-success w-sm">Crea</button>
        </div>
      </div>
      <!-- end col -->
      <div class="col-lg-4">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Privacy</h5>
          </div>
          <div class="card-body">
            <div>
              <label for="choices-privacy-status-input" class="form-label"
                >Status</label
              >
              <Multiselect
                v-model="value3"
                :close-on-select="true"
                :searchable="true"
                :create-option="true"
                :options="[
                  { value: 'Private', label: 'Private' },
                  { value: 'Team', label: 'Team' },
                  { value: 'Public', label: 'Public' },
                ]"
              />
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->

        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Tags</h5>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <label for="choices-categories-input" class="form-label"
                >Categories</label
              >
              <Multiselect
                v-model="value4"
                :close-on-select="true"
                :searchable="true"
                :create-option="true"
                :options="[
                  { value: 'Designing', label: 'Designing' },
                  { value: 'Development', label: 'Development' },
                ]"
              />
            </div>

            <div>
              <label for="choices-text-input" class="form-label">Skills</label>
              <Multiselect
                class="form-control"
                v-model="value"
                mode="tags"
                :close-on-select="true"
                :searchable="true"
                :create-option="true"
                :options="[
                  { value: 'UI/UX', label: 'UI/UX' },
                  { value: 'Figma', label: 'Figma' },
                  { value: 'HTML', label: 'HTML' },
                  { value: 'CSS', label: 'CSS' },
                  { value: 'Javascript', label: 'Javascript' },
                  { value: 'C#', label: 'C#' },
                  { value: 'Nodejs', label: 'Nodejs' },
                ]"
              />
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->

        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Members</h5>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <label for="choices-lead-input" class="form-label"
                >Team Lead</label
              >
              <Multiselect
                v-model="value5"
                :close-on-select="true"
                :searchable="true"
                :create-option="true"
                :options="[
                  { value: 'Brent Gonzalez', label: 'Brent Gonzalez' },
                  { value: 'Darline Williams', label: 'Darline Williams' },
                  { value: 'Sylvia Wright', label: 'Sylvia Wright' },
                  { value: 'Ellen Smith', label: 'Ellen Smith' },
                  { value: 'Jeffrey Salazar', label: 'Jeffrey Salazar' },
                  { value: 'Mark Williams', label: 'Mark Williams' },
                ]"
              />
            </div>

            <div>
              <label class="form-label">Team Members</label>
              <div class="avatar-group">
                <a
                  href="javascript: void(0);"
                  class="avatar-group-item shadow"
                  data-bs-toggle="tooltip"
                  data-bs-trigger="hover"
                  data-bs-placement="top"
                  title="Brent Gonzalez"
                >
                  <div class="avatar-xs">
                    <img
                      src="@/assets/images/users/avatar-3.jpg"
                      alt=""
                      class="rounded-circle img-fluid"
                    />
                  </div>
                </a>
                <a
                  href="javascript: void(0);"
                  class="avatar-group-item shadow"
                  data-bs-toggle="tooltip"
                  data-bs-trigger="hover"
                  data-bs-placement="top"
                  title="Sylvia Wright"
                >
                  <div class="avatar-xs">
                    <div class="avatar-title rounded-circle bg-secondary">
                      S
                    </div>
                  </div>
                </a>
                <a
                  href="javascript: void(0);"
                  class="avatar-group-item shadow"
                  data-bs-toggle="tooltip"
                  data-bs-trigger="hover"
                  data-bs-placement="top"
                  title="Ellen Smith"
                >
                  <div class="avatar-xs">
                    <img
                      src="@/assets/images/users/avatar-4.jpg"
                      alt=""
                      class="rounded-circle img-fluid"
                    />
                  </div>
                </a>
                <a
                  href="javascript: void(0);"
                  class="avatar-group-item shadow"
                  data-bs-toggle="tooltip"
                  data-bs-trigger="hover"
                  data-bs-placement="top"
                  title="Add Members"
                >
                  <div
                    class="avatar-xs"
                    data-bs-toggle="modal"
                    data-bs-target="#inviteMembersModal"
                  >
                    <div
                      class="avatar-title fs-16 rounded-circle bg-light border-dashed border text-primary"
                    >
                      +
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
